// Packages Import
import React from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import { FormattedMessage } from "react-intl"

// Other component imports
import DashboardAppliedJobsCSS from "./style"
import { selectAppliedJobs } from "../../../redux/slices/jobs"
import Image from "../../image"

// Utils
import { renderJobButton } from "../utils"

const DashboardAppliedJobs = (props) => {
  const showDetails = (appliedId) => navigate(`applied-job?id=${appliedId}`)

  const appliedJobs = useSelector(selectAppliedJobs)
  return (
    <DashboardAppliedJobsCSS>
      {appliedJobs.length > 0 ? (
        appliedJobs.map((appliedJob, index) => (
          <div className="job" key={index}>
            {renderJobButton(appliedJob, index, "applied", () =>
              showDetails(appliedJob.id)
            )}
          </div>
        ))
      ) : (
        <div className="no__job">
          <Image
            className="no__activity__image"
            imgName="no_activity.png"
            alt="no_activity"
          />
          <div className="no__activity__texts">
            <p className="no__activity__text">
              <FormattedMessage id="jobfair.applied.noActivity" />
            </p>
            <p className="no__activity__text">
              <FormattedMessage id="jobfair.applied.noActivity2" />
            </p>
          </div>
        </div>
      )}
    </DashboardAppliedJobsCSS>
  )
}

export default DashboardAppliedJobs
