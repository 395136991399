import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ApplicationsDashboardPage from "../../components/DashboardPage/index-applications"
import DashboardAppliedJobs from "../../components/DashboardPage/DashboardAppliedJobs"
import { useSelector } from "react-redux"

const ApplicationsIndex = () => {
  const [needFeedback, setNeedFeedback] = useState(false)
  const jobState = useSelector((state) => state.jobs)
  const applicant = jobState.applicant
  const jobs = Object.values(jobState.jobs)

  const checkIsNeedFeedback = () => {
    for (let i = 0; i < jobs.length; i++) {
      if (jobs[i].applied) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (!applicant.is_already_give_feedback) {
      setNeedFeedback(checkIsNeedFeedback())
    }
  }, [jobState])

  return (
    <Layout>
      <SEO title="Applied Jobs" />
      <ApplicationsDashboardPage
        def="1"
        setNeedFeedback={setNeedFeedback}
        applicant={applicant}
        needFeedback={needFeedback}
      >
        <DashboardAppliedJobs />
      </ApplicationsDashboardPage>
    </Layout>
  )
}

export default ApplicationsIndex
